import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var req = {
			fields: this.resultFields,
			filter: {
				//unitgroupid: 25551
				//'region.id': 8155
				'region.id': parseInt(this.$el.dataset.regionid, 10)
			},
			sorting: 'random',
			pricelist: true,
			max: 3
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
			});

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},

	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getAvgStars: function (stars) {
			var round = Math.round(stars / 0.5) * 0.5;
			return round;
		},
		getUnitSearchTarget: function (id) {
			return '_self';
			/*	if (window.screen.width < 768) {
					//probably phone?
					return '_self';
				} else {
					return 'unit_' + id;
				} */
		},

		myEventHandler(e) {
			//	console.log(window.innerWidth);
		}
	}

};