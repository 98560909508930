var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "hidden-print contact-form voucher-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-horizontal" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status == "done",
                  expression: "status=='done'"
                }
              ],
              staticClass: "alert alert-info"
            },
            [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-hidden": "true" },
                  on: {
                    click: function($event) {
                      return _vm.removeVoucher()
                    }
                  }
                },
                [_vm._v("×")]
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.voMsg("voucher.success", [
                      _vm.voucher && _vm.voucher.code
                    ])
                  )
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-secondary btn-block remove-voucher mt-3",
                  attrs: { type: "button", "aria-hidden": "true" },
                  on: {
                    click: function($event) {
                      return _vm.removeVoucher()
                    }
                  }
                },
                [_vm._v("Rabattcode entfernen")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status == "failed",
                  expression: "status=='failed'"
                }
              ],
              staticClass: "alert alert-warning"
            },
            [_c("span", [_vm._v(_vm._s(_vm.voMsg("tpl.voucherfailed")))])]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status == "ready" || _vm.status == "failed",
                  expression: "status=='ready' || status=='failed'"
                }
              ]
            },
            [
              _c("div", { staticClass: "search-bar-content" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reqCode,
                          expression: "reqCode"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "input_code_voucher",
                        required: "required"
                      },
                      domProps: { value: _vm.reqCode },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.reqCode = $event.target.value
                        }
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-go btn-block",
                  attrs: { type: "submit" }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.submitLabel) +
                      "\n            "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status == "submitting",
                  expression: "status=='submitting'"
                }
              ],
              staticClass: "contact-sending",
              attrs: { "ng-cloak": "" }
            },
            [_vm._m(0)]
          ),
          _vm._v(" "),
          _c("voffice-error-message", { attrs: { error: _vm.error } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "bounce1" }),
      _vm._v(" "),
      _c("div", { staticClass: "bounce2" }),
      _vm._v(" "),
      _c("div", { staticClass: "bounce3" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }