import { render, staticRenderFns } from "./GuestBox.vue?vue&type=template&id=525ea42e&scoped=true&"
import script from "./GuestBox.vue?vue&type=script&lang=js&"
export * from "./GuestBox.vue?vue&type=script&lang=js&"
import style0 from "./GuestBox.vue?vue&type=style&index=0&id=525ea42e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "525ea42e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('525ea42e')) {
      api.createRecord('525ea42e', component.options)
    } else {
      api.reload('525ea42e', component.options)
    }
    module.hot.accept("./GuestBox.vue?vue&type=template&id=525ea42e&scoped=true&", function () {
      api.rerender('525ea42e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8a7aqQMHCSybv/JS/comp/GuestBox.vue"
export default component.exports